import { useMemo, useRef, useEffect, useState, useSyncExternalStore } from 'react'

type ResultBox<T> = { v: T }

export function useConstant<T>(fn: () => T): T {
  // RSC compat
  if (typeof document === 'undefined') {
    return useMemo(() => fn(), [])
  }

  const ref = useRef<ResultBox<T>>()

  if (!ref.current) {
    ref.current = { v: fn() }
  }

  return ref.current.v
}

const emptyFn = () => {}
const emptyFnFn = () => emptyFn

export function useDidFinishSSR<A = boolean>(
  value?: A,
  options?: {
    sync?: boolean
  }
): A | false {
  if (typeof window !== 'undefined') {
    return (value ?? true) as any
  }

  if (options?.sync) {
    return useSyncExternalStore(
      emptyFnFn,
      () => (value == undefined ? true : value),
      () => false as any
    )
  }

  const [cur, setCur] = useState<any>(value)
  useEffect(() => {
    setCur(value ?? true)
  }, [])
  return cur ?? false
}

// const useIsomorphicLayoutEffect =
//   typeof window === 'undefined' ? useEffect : useLayoutEffect

export function useDidFinishSSRSync<A = boolean>(value?: A): A | false {
  return useDidFinishSSR(value, {
    sync: true,
  })
}

type FunctionOrValue<Value> = Value extends () => infer X ? X : Value

export function useClientValue<Value>(value?: Value): FunctionOrValue<Value> | undefined {
  const done = useDidFinishSSR()
  return !done ? undefined : typeof value === 'function' ? value() : value
}
