import { IconTwitter } from '@my/icon/src/IconTwitter'
import { ClientOnly } from 'app/components/ClientOnly'
import { useMyDreamBoyMode } from 'app/utils/hooks/useMyDreamBoyMode'
import Link from 'next/link'

export const TwitterLinkUrl = 'https://twitter.com/mydreamboyPr'

export const TwitterLink = () => {
  const isMyDreamBoy = useMyDreamBoyMode()
  return (
    isMyDreamBoy && (
      <ClientOnly>
        <Link href={TwitterLinkUrl} target="_blank">
          <div className="flex aspect-square w-11 items-center justify-center rounded-sm hover:bg-gray-300">
            <IconTwitter className="h-6 w-6" />
          </div>
        </Link>
      </ClientOnly>
    )
  )
}
