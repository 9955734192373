import { observable } from '@legendapp/state'
import { configureObservablePersistence, persistObservable } from '@legendapp/state/persist'
import { ObservablePersistLocalStorage } from '@legendapp/state/persist-plugins/local-storage'
import { Enums } from '@my/supabase/types'

configureObservablePersistence({
  pluginLocal: ObservablePersistLocalStorage,
})

const modelVersion$ = observable<Enums<'model_version'> | undefined>(undefined)

if (typeof window !== 'undefined') {
  persistObservable(modelVersion$, {
    local: 'globalModelVersion',
  })
}

export const isStandardModelVersion = (version: Enums<'model_version'>) => version === 'v0'

export const isNsfwModelVersion = (version: Enums<'model_version'>) => version === 'v1'

export const isModelVersionStandard = () => modelVersion$.get() === 'v0'

export const isModelVersionNsfw = () => modelVersion$.get() === 'v1'

export const getModelVersion = () => modelVersion$.get()

export const getModelVersionWithStandard = () => modelVersion$.get() ?? 'v0'

export const getModelVersionWithNsfw = () => modelVersion$.get() ?? 'v1'

export const setModelVersion = (version: Enums<'model_version'>) => modelVersion$.set(version)

export const setStandardModelVersion = () => modelVersion$.set('v0')

export const setNsfwModelVersion = () => modelVersion$.set('v1')

export const getExplorePostLink = () => (isModelVersionStandard() ? '/explore' : '/v1/explore')

export const getExploreModelLink = () =>
  isModelVersionStandard() ? '/explore/models' : '/v1/explore/models'

export const getExplorePromptsLink = () =>
  isModelVersionStandard() ? '/explore/prompts' : '/v1/explore/prompts'

export const getExplorePromptPostsLink = () =>
  isModelVersionStandard() ? '/explore/prompts/[id]' : '/v1/explore/prompts/[id]'
